<template>

  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <h5 class="mt-3">Liste teilen</h5>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">
                cancel
              </span>
            </button>
          </div>

          <div class="modal-body">

            <div class="row">
              <div>

                <div class="form-group">
                  <input style="width: 100%;" type="text" class="form-control" @blur="validateEmailInput()" placeholder="E-Mail Adresse" v-model="eMail">
                </div>
                <span class="validation-error" v-show="showEmailError">Gib deine E-Mail Adresse an</span>

                <div class="mt20">
                  <div class="form-group form-inline">
                    <select class="form-select" style="width: 188px; padding: 11px 10px;" v-model="selectedRole" placeholder="Berechtigung wählen" <option="" v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}
                    </select>
                  </div>

                  <div class="form-group form-inline btn-form" style="text-align:right; padding-left:10px;">
                    <button class="btn btn-hover" type="primary" @click="shareThisCollection">Share</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="user-container mt20">

              <user-row v-for="user in users" :key="user.id" :user="user" :roles="roles" @reload="getUsersForCollection"></user-row>

            </div>
          </div>

          <div class="modal-footer">


            <div class="btn btn-hover" @click="closeModal">
              Abbrechen
            </div>

          </div>
        </div>
      </div>


    </div>
  </transition>

</template>

<script>
  import UserRow from './shareDialogUserRow';
  import { validEmail } from '@/utils/validate';
  import { getCollectionRoles, getUsersForCollection, shareCollection} from '@/api/collection';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'ShareCollectionDialog',
    components: { UserRow },
    props: {
      collection: {
        type: Object,
        default: null,
        required: true,
      }
    },
    data() {
      return {
        loader: null,
        showThisDialog: this.showDialog,
        roles: [],
        users: [],
        selectedRole: null,
        eMail: '',
        showEmailError: false,
        appUrl: window.location.origin, 
        appId: this.$appId
      };
    },
    mounted() {
      this.$nextTick(function () {
        this.getCollectionRoles();
        this.getUsersForCollection();
      });
    },
    computed: {
    },
    methods: {
      showLoader,
      hideLoader,
      validEmail,
      validateEmailInput(){
        if(!validEmail(this.eMail)){
          this.showEmailError=true;
          return false;
        }
        else{
          this.showEmailError=false;
          return true;
        }
      },
      getCollectionRoles(){
        getCollectionRoles()
        .then(response => {
          this.roles = response.data;
          /*check if this element has multiple roles and assign the last role as default*/
          if(this.roles.length>0){
            this.selectedRole = this.roles[this.roles.length-1].id;
          }
        })
        .catch(error => {
          console.log(error);
        });
      },
      getUsersForCollection(){
        getUsersForCollection(this.collection.id)
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.log(error);
        });
      },
      shareThisCollection(){
        /*check if an E-Mail Adress is given*/
        if(this.validateEmailInput()){
          this.loader = this.showLoader(this.loader);
          shareCollection(this.collection.id, this.eMail, this.selectedRole, this.appUrl, this.appId)
          .then(() => {
            this.getUsersForCollection();

            this.$notify({
              duration: 2500,
              title: 'Merkliste geteilt',
              text: 'Die Merkliste wurde erfolgreich geteilt.'
            });

          })
          .catch(error => {
            console.log(error);

            this.$notify({
              type: 'error',
              duration: 2500,
              title: 'Fehler!',
              text: 'Beim Teilen der Merkliste ist ein Fehler aufgetreten.'
            });

          })
          .finally(() => {
            this.loader = this.hideLoader(this.loader);
            this.eMail = '';
          });
        }
      },
      closeModal(){
        this.$emit("closeModal");
      },
    },
  };

</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  .validation-error{
    padding: 0px 15px;
  }

  .user-container{
    padding: 0px 5px;
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .form-inline input,.form-inline select{
    line-height: 1.2;
  }

  .form-inline{
    display: inline-block;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 355px;
    margin: 0px auto;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header{
    color:$primary;
  }

  .modal-header button{
    background-color: transparent;
    color: grey;
  }

  .modal-body{
    padding: 5px 1em;
  }

  .modal-header{
    border-bottom: none
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-default-button {
    float: right;
  }

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-footer{
  border-top: none;
  justify-content: center;
}

.validation-error {
  color: red;
}

body[dir="rtl"] {
  .close {
    margin: 0;
  }

  .btn-form {
    padding-left: 0 !important;

    button {
      margin-right: 10px;
    }
  }
}

</style>

